console.log('Affiliate products script loaded');

// Initialize after DOM content is loaded
document.addEventListener('DOMContentLoaded', () => {
  console.log('DOM loaded - initializing affiliate products');
  let productsData = null;
  let dataLoadedCallback = null;

  // Function to load the products data
  const loadProductsData = () => {
    console.log('Loading products data...');
    if (productsData) {
      return Promise.resolve(productsData);
    }

    return fetch('/amazon_affiliate_products.json')
      .then(response => {
        console.log('Received response:', response);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        productsData = data;
        console.log('Loaded products data:', productsData);
        if (dataLoadedCallback) {
          dataLoadedCallback(productsData);
        }
        return productsData;
      })
      .catch(error => {
        console.error('Error loading affiliate products:', error);
        return null;
      });
  };

  // Load data immediately
  loadProductsData();

  // Helper function to validate product data
  const isValidProduct = (product) => {
    const isValid = product.title &&
           product.imageUrl &&
           product.linkUrl &&
           product.title.trim() !== '' &&
           product.imageUrl.trim() !== '' &&
           product.linkUrl.trim() !== '';
    console.log('Validating product:', product, 'isValid:', isValid);
    return isValid;
  };

  // Function to display relevant products based on selected activities
  window.showAffiliateProducts = (activities) => {
    console.log('showAffiliateProducts called with activities:', activities);

    const showProducts = (data) => {
      const affiliateProducts = document.getElementById('affiliateProducts');
      if (!affiliateProducts) {
        console.error('affiliateProducts element not found');
        return;
      }

      const productCarousel = affiliateProducts.querySelector('.product-carousel');
      if (!productCarousel) {
        console.error('product-carousel element not found');
        return;
      }

      if (!data) {
        console.log('No products data or activities');
        affiliateProducts.style.display = 'none';
        return;
      }

      // Add "All" activity if not already present
      if (!activities.includes('All')) {
        activities.push('All');
      }

      console.log('Current productsData:', data);

      // Clear existing products
      productCarousel.innerHTML = '';

      // Collect valid products for selected activities
      const relevantProducts = activities.flatMap(activity => {
        console.log('Processing activity:', activity);
        const products = data[activity] || [];
        console.log('Products for activity:', products);
        return products.filter(isValidProduct);
      });

      console.log('Relevant products after filtering:', relevantProducts);

      // Only proceed if we have valid products
      if (relevantProducts.length === 0) {
        console.log('No valid products found');
        affiliateProducts.style.display = 'none';
        return;
      }

      // Shuffle products to show different ones each time
      const shuffledProducts = relevantProducts.sort(() => Math.random() - 0.5);
      console.log('Shuffled products:', shuffledProducts);

      // Display up to 6 products
      shuffledProducts.slice(0, 6).forEach(product => {
        console.log('Creating element for product:', product);
        const productElement = document.createElement('div');
        productElement.className = 'affiliate-product';
        productElement.innerHTML = `
          <a href="${product.linkUrl}" target="_blank" rel="nofollow">
            <img src="${product.imageUrl}" alt="${product.title}">
            <h4>${product.title}</h4>
          </a>
        `;
        productCarousel.appendChild(productElement);
      });

      // Show the products section
      console.log('Showing affiliate products section');
      affiliateProducts.style.display = 'block';
    };

    // If data is already loaded, use it immediately
    if (productsData) {
      showProducts(productsData);
    } else {
      // Otherwise, load the data first
      dataLoadedCallback = showProducts;
      console.log('Data loaded callback set');
      loadProductsData();
    }
  };
});
