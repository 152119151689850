import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Loading controller connected")
    
    document.addEventListener('turbo:submit-start', this.showModal.bind(this))
  }

  showModal(event) {
    if (!event.target.matches('#boat-form')) return
    
    const loadingModal = document.getElementById('loadingModal')
    if (!loadingModal) {
      console.error('Modal element not found')
      return
    }
    
    const submitButton = event.target.querySelector('input[type="submit"]')
    if (submitButton) {
      submitButton.value = "Finding your perfect boat..."
      submitButton.disabled = true
    }

    loadingModal.style.display = 'block'

    // Show affiliate products
    const activities = Array.from(document.querySelectorAll('.activity-checkbox:checked'))
      .map(checkbox => checkbox.value)
    
    if (typeof window.showAffiliateProducts === 'function') {
      window.showAffiliateProducts(activities)
    } else {
      console.error('showAffiliateProducts function not found - waiting for script to load')
      setTimeout(() => {
        if (typeof window.showAffiliateProducts === 'function') {
          window.showAffiliateProducts(activities)
        } else {
          console.error('showAffiliateProducts function still not found after delay')
        }
      }, 500)
    }

    if (typeof gtag_report_conversion === 'function') {
      gtag_report_conversion()
    }
  }
}
