// Initialize form controls
function initializeForm() {
  function blurAllTextInputs() {
    document.querySelectorAll('input[type="text"]').forEach(input => input.blur());
  }

  const experienceSlider = document.getElementById('experience');
  const experienceLabel = document.getElementById('experience-label');
  const experienceText = document.getElementById('experience-text');
  const experienceLevels = ['Novice', 'Beginner', 'Intermediate', 'Advanced', 'Expert'];

  // Enhanced slider interactions
  function updateSlider(slider, label, value, formatter) {
    label.textContent = formatter(value);
    label.classList.add('value-changed');
    setTimeout(() => label.classList.remove('value-changed'), 500);
  }

  if (experienceSlider) {
    experienceSlider.addEventListener('input', function () {
      blurAllTextInputs();
      updateSlider(
        experienceSlider,
        experienceLabel,
        experienceSlider.value,
        value => experienceLevels[value]
      );
      experienceText.value = experienceLevels[experienceSlider.value];
    });
  }

  const budgetSlider = document.getElementById('budget');
  const budgetLabel = document.getElementById('budget-label');
  const budgetText = document.getElementById('budget-text');
  const budgetRanges = ['Below $5,000', '$5,000 - $25,000', '$25,000 - $75,000', '$75,000 - $200,000', 'Above $200,000'];

  if (budgetSlider) {
    budgetSlider.addEventListener('input', function () {
      blurAllTextInputs();
      updateSlider(
        budgetSlider,
        budgetLabel,
        budgetSlider.value,
        value => budgetRanges[value]
      );
      budgetText.value = budgetRanges[budgetSlider.value];
    });
  }

  const passengersSlider = document.getElementById('passengers');
  const passengersLabel = document.getElementById('passengers-label');
  const passengersText = document.getElementById('passengers-text');

  if (passengersSlider) {
    passengersSlider.addEventListener('input', function () {
      blurAllTextInputs();
      const value = passengersSlider.value;
      const text = value == 10 ? '10+ passengers' : `${value} passenger${value > 1 ? 's' : ''}`;
      updateSlider(
        passengersSlider,
        passengersLabel,
        value,
        () => text
      );
      passengersText.value = text;
      passengersLabel.classList.add('value-changed');
      setTimeout(() => passengersLabel.classList.remove('value-changed'), 500);
    });
  }

  const activityCheckboxes = document.querySelectorAll('.activity-checkbox');
  const otherActivityInput = document.getElementById('other-activity');
  const activityInput = document.getElementById('activity');

  function updateActivity() {
    const selectedActivities = Array.from(activityCheckboxes)
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);

    if (otherActivityInput?.value) {
      selectedActivities.push(otherActivityInput.value);
    }

    if (activityInput) {
      activityInput.value = selectedActivities.join(', ');
    }
  }

  // Smart defaults based on common selections
  const popularConfigs = {
    fishing: {
      passengers: 4,
      budget: 2, // $25,000 - $75,000
      experience: 1 // Beginner
    },
    cruising: {
      passengers: 6,
      budget: 3, // $75,000 - $200,000
      experience: 2 // Intermediate
    }
  };

  activityCheckboxes.forEach(checkbox => {
    checkbox.addEventListener('change', (e) => {
      if (e.target.checked && popularConfigs[e.target.value.toLowerCase()]) {
        const config = popularConfigs[e.target.value.toLowerCase()];
        
        // Only apply defaults if values haven't been set
        if (passengersSlider && !passengersSlider.value) {
          passengersSlider.value = config.passengers;
          passengersSlider.dispatchEvent(new Event('input'));
        }
        
        if (budgetSlider && !budgetSlider.value) {
          budgetSlider.value = config.budget;
          budgetSlider.dispatchEvent(new Event('input'));
        }
        
        if (experienceSlider && !experienceSlider.value) {
          experienceSlider.value = config.experience;
          experienceSlider.dispatchEvent(new Event('input'));
        }
      }
      updateActivity();
    });
  });

  if (otherActivityInput) {
    otherActivityInput.addEventListener('input', updateActivity);
  }

  const form = document.getElementById('boat-form');
  if (form) {
    form.addEventListener('submit', function (event) {
      // Prevent form submission if query is disabled
      if (window.disableQuery) {
        event.preventDefault();
      }
    });
  }
}

// Initialize on first load
document.addEventListener('DOMContentLoaded', initializeForm);

// Initialize on Turbo navigation
document.addEventListener('turbo:load', initializeForm);
document.addEventListener('turbo:frame-load', initializeForm);
document.addEventListener('turbo:render', initializeForm);