import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "email", "message", "submitButton"]
  static values = {
    uuid: String
  }

  connect() {
    this.isSubmitting = false
    // Ensure the form doesn't submit normally
    this.formTarget.addEventListener('submit', (e) => {
      e.preventDefault()
      return false
    })
  }

  subscribe(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    // If already submitting, prevent duplicate submission
    if (this.isSubmitting) {
      return
    }

    this.isSubmitting = true
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.add('opacity-50')
    this.messageTarget.textContent = ''
    this.messageTarget.classList.remove('text-green-600', 'text-red-600')

    const email = this.emailTarget.value
    const savedSearchUuid = this.hasUuidValue ? this.uuidValue : null

    fetch('/subscriptions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json'  // Ensure we get JSON response
      },
      body: JSON.stringify({
        email: email,
        saved_search_uuid: savedSearchUuid
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        this.messageTarget.textContent = 'Great! We\'ve emailed you a permanent link to your boat matches.'
        this.messageTarget.classList.add('text-green-600')
        this.formTarget.reset()
      } else {
        this.messageTarget.textContent = data.message || 'An error occurred. Please try again.'
        this.messageTarget.classList.add('text-red-600')
      }
    })
    .catch(error => {
      console.error('Error:', error)
      this.messageTarget.textContent = 'An error occurred. Please try again.'
      this.messageTarget.classList.add('text-red-600')
    })
    .finally(() => {
      this.isSubmitting = false
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove('opacity-50')
    })

    return false
  }
}
